.paginator-btn {
  display: inline-block;
  padding: 15px 20px;
  text-decoration: none;
  background-color: white;
  color: #0054B9;
  font-size: 16px;
  font-weight: normal;
  border: 1px solid #DDDDDD;
  margin-top: 10px;
}

.paginator-btn_shine {
  background-color: #3388CC !important;
  box-shadow: 0 0 12px #3388CC !important;
  border-color: #3388CC !important;
  color: white !important;
}

.paginator-btn:visited {
  color: #0054B9;
}

.paginator-btn_rigth {
  float: right;
}

.paginator-btn_wrapper {
  height: 50px;
}
