.find-town__btn {
  display: inline-block;
  margin-right: 5px;
  border-radius: 5px;
  width: calc(100% - 20px) !important;
  color: white;
  text-decoration: none;
  padding: 10px 10px 15px;
  height: 15px;
  font-size: 16px;
  font-weight: 500;
  background-color: #0092FF;
  text-align: center;
}

.find-town__btn:visited {
  color: white;
}

.find-town__btn_shadow {
  box-shadow: 2px 2px 5px #A5A0A6;
}

.find-town__title {
  display: inline-block;
  padding: 15px 10px 0 0;
  text-decoration: none;
  color: #333333;
}

.find-town__form-wrap {
  position: relative;
  display: inline-block;
  width: calc(100% - 10px) !important;
  background-color: white;
  padding-left: 10px;
  padding-top: 3px;
  padding-bottom: 5px;
  border-radius: 5px;
  box-shadow: inset 0 0 5px #7E7E7E;
  margin-top: 5px;
  text-align: left;
}

.find-town__form-wrap_nojqm {
  width: calc(100% - 50px) !important;
  position: relative;
}

.find-town__form-esc_nojqm {
  display: inline-block;
  position: absolute;
  top: 12px;
  right: 10px;
  height: 25px;
  width: 25px;
  background-color: #B2B2B2;
  border-radius: 50%;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22iso-8859-1%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2214px%22%20height%3D%2214px%22%20viewBox%3D%220%200%2014%2014%22%20style%3D%22enable-background%3Anew%200%200%2014%2014%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpolygon%20fill%3D%22%23FFF%22%20points%3D%2214%2C3%2011%2C0%207%2C4%203%2C0%200%2C3%204%2C7%200%2C11%203%2C14%207%2C10%2011%2C14%2014%2C11%2010%2C7%20%22%2F%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: center;
}

.find-town__magnifier {
  display: inline-block;
  position: absolute;
  width: 14px;
  height: 14px;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22iso-8859-1%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2214px%22%20height%3D%2214px%22%20viewBox%3D%220%200%2014%2014%22%20style%3D%22enable-background%3Anew%200%200%2014%2014%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20d%3D%22M10.171%2C8.766c0.617-0.888%2C0.979-1.964%2C0.979-3.126c0-3.037-2.463-5.5-5.5-5.5s-5.5%2C2.463-5.5%2C5.5s2.463%2C5.5%2C5.5%2C5.5%20c1.152%2C0%2C2.223-0.355%2C3.104-0.962l3.684%2C3.683l1.414-1.414L10.171%2C8.766z%20M5.649%2C9.14c-1.933%2C0-3.5-1.567-3.5-3.5%20c0-1.933%2C1.567-3.5%2C3.5-3.5c1.933%2C0%2C3.5%2C1.567%2C3.5%2C3.5C9.149%2C7.572%2C7.582%2C9.14%2C5.649%2C9.14z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  left: 5px;
  top: 10px;
  opacity: 0.5;
}

.find-town__magnifier_nojqm {
  left: 9px;
  top: 15px;
}


.find-town__esc {
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-right: 10px;
  float: right;
  margin-top: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22iso-8859-1%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2214px%22%20height%3D%2214px%22%20viewBox%3D%220%200%2014%2014%22%20style%3D%22enable-background%3Anew%200%200%2014%2014%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpolygon%20fill%3D%22%23FFF%22%20points%3D%2214%2C3%2011%2C0%207%2C4%203%2C0%200%2C3%204%2C7%200%2C11%203%2C14%207%2C10%2011%2C14%2014%2C11%2010%2C7%20%22%2F%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
  background-color: #9C979B;
  border-radius: 50%;
}

.find-town__inp {
  padding-top: 4px;
  font-size: 15px;
  border: none;
  width: 80%;
  padding-left: 12px;
  outline: none;
}

.find-town__inp_nojqm {
  padding-left: 20px;
  height: 30px;
  font-size: 20px;
}

.find-town__find {
  width: calc(92% - 32px) !important;
  margin: 0;
  padding-left: 0;
  list-style-type: none;
  margin-top: 8px;
}

.find-town__goto {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: url('/static/_v1/pd/icons/arrows/circle-goto.png') no-repeat center;
  margin: 7px 0 0 5px;
  float: right;
}

.find-town__goto_nojqm {
  margin: 3px;
  position: absolute;
  right: -42px;
}
