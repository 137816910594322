span.stars {
  margin-right: 10px;
  height: 100%;
}

span.stars,
span.stars span {
  display: inline-block;
  width: 80px;
  height: 18px;
  background: transparent url('/static/_v1/pd/icons/old-stars/star-16-off.png') repeat-x scroll 0 3px;
}

span.stars span {
  background: transparent url('/static/_v1/pd/icons/old-stars/star-16-on.png') repeat-x scroll 0 3px;
}

.stars__wrap {
  display: inline-block;
  position: relative;
  min-height: 22px;
}

span.balloon_stars,
span.balloon_stars span {
  height: 12px;
  width: 60px;
  background-size: 12px;
}

span.balloon_stars {
  margin-right: 15px !important;
  float: left;
}

.doctor_stars,
.drug_stars {
  position: relative;
  bottom: 4px;
}

.left_float {
  float: left;
}
